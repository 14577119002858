import Loadable from "@loadable/component";

const EpisodeExternalPlayerLinks = Loadable(() =>
  import("./EpisodeExternalPlayerLinks")
);

function EpisodeExternalPlayerLinksAsyncLoading(props) {
  return null;
}

const EpisodeExternalPlayerLinksContainer = (props) => {
  return (
    <EpisodeExternalPlayerLinks
      {...props}
      fallback={<EpisodeExternalPlayerLinksAsyncLoading />}
    />
  );
};

export default EpisodeExternalPlayerLinksContainer;
