// https://raw.githubusercontent.com/rafrex/react-router-hash-link

import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";

let hashFragment = "";
let observer = null;
let asyncTimerId = null;
let scrollFunction = null;

function reset() {
  hashFragment = "";
  if (observer !== null) {
    observer.disconnect();
  }
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

function getElAndScroll() {
  const element = document.getElementById(hashFragment);
  if (element !== null) {
    scrollFunction(element);
    reset();
    return true;
  }
  return false;
}

function hashLinkScroll() {
  // Push onto callback queue so it runs after the DOM is updated
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }
      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      // if the element doesn't show up in 10 seconds, stop checking
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, 10000);
    }
  }, 0);
}

export function genericHashLink(props, As) {
  function handleClick(e) {
    reset();
    if (props.onClick) {
      props.onClick(e);
    }
    // e.preventDefault();
    if (typeof props.to === "string") {
      hashFragment = props.to.split("#").slice(1).join("#");
    } else if (
      typeof props.to === "object" &&
      typeof props.to.hash === "string"
    ) {
      hashFragment = props.to.hash.replace("#", "");
    }
    if (hashFragment !== "") {
      scrollFunction =
        props.scroll ||
        ((el) => {
          if (props.smooth) {
            el.scrollIntoView({ behavior: "smooth" });
          } else {
            el.scrollIntoView();
          }
        });
      hashLinkScroll();
    }
  }

  const filteredProps = { ...props };

  delete filteredProps.scroll;
  delete filteredProps.smooth;
  delete filteredProps.history;
  delete filteredProps.staticContext;

  return (
    <As {...filteredProps} onClick={handleClick}>
      {props.children}
    </As>
  );
}

function HashLinkA(props) {
  return genericHashLink(props, Link);
}

function NavHashLinkA(props) {
  return genericHashLink(props, NavLink);
}

const propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
  scroll: PropTypes.func,
  smooth: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
  onClick: null,
  children: null,
  scroll: null,
  smooth: null,
  to: null,
};

genericHashLink.propTypes = propTypes;
HashLinkA.propTypes = propTypes;
NavHashLinkA.propTypes = propTypes;

genericHashLink.defaultProps = defaultProps;
HashLinkA.defaultProps = defaultProps;
NavHashLinkA.defaultProps = defaultProps;

export const HashLink = HashLinkA;
