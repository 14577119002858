import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import deepmerge from "ramda/src/mergeDeepRight";
import { forwardRef, memo, Fragment, useMemo } from "react";

import StandardButton from "components/Buttons/StandardButton";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  label: {},
  labelIcon: {},
  labelText: {
    ...gStyles.avalonBold,
    fontSize: ".7rem",
    marginTop: ".8rem",

    [ScreenSizes.lgAndAbove]: {
      fontSize: ".65rem",
    },
  },
};

const buttonStyles = {
  button: {
    flexDirection: "column",
    textAlign: "center",
    color: colours.bodyText,
  },
};

const disabledButtonStyles = {
  button: {
    ...buttonStyles.button,
    color: colours.lightGrey,
  },
};

const FlatButtonWithIcon = forwardRef((props, ref) => {
  const {
    icon,
    iconSize,
    label,
    iconSpin,
    disabled,
    customStyles: passedCustomStyles,
    ...rest
  } = props;

  const { styles } = useStyles(baseStyles, props);
  const iconStyle = useMemo(() => ({ fontSize: iconSize }), [iconSize]);
  const customStyles = useMemo(() => {
    const butStyles = disabled ? disabledButtonStyles : buttonStyles;

    if (passedCustomStyles) {
      return deepmerge(butStyles, passedCustomStyles);
    }

    return butStyles;
  }, [passedCustomStyles, disabled]);

  delete rest.styles;

  return (
    <StandardButton
      variation="clear"
      disabled={disabled}
      {...rest}
      ref={ref}
      styles={null}
      customStyles={customStyles}
      label={
        <Fragment>
          <FontAwesomeIcon
            className={css(styles.label, styles.labelIcon)}
            icon={icon}
            style={iconStyle}
            spin={iconSpin}
          />
          {label && (
            <div className={css(styles.label, styles.labelText)}>{label}</div>
          )}
        </Fragment>
      }
    />
  );
});

FlatButtonWithIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  iconSize: PropTypes.string,
  iconSpin: PropTypes.bool,
  disabled: PropTypes.bool,
  customStyles: PropTypes.object,
};
FlatButtonWithIcon.defaultProps = {
  iconSize: "1.7rem",
  iconSpin: false,
  disabled: false,
  customStyles: null,
};

FlatButtonWithIcon.displayName = "FlatButtonWithIcon";

export default memo(FlatButtonWithIcon);
