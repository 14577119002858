/* eslint-disable max-len */

export default {
  prefix: "fpc",
  iconName: "pc-add-to-list",
  icon: [
    570,
    512,
    [],
    null,
    "M472.121212,466.278106 L472.121212,488.988764 L391.515152,480.25032 L391.515152,37.502489 L472.121212,28.7640449 L472.121212,68.4325525 L472.121212,466.278106 Z M500.909091,446.774642 L500.909091,65.2253576 L570,57.5280899 L570,454.47191 L500.909091,446.774642 Z M362.727273,494.564724 L362.727273,512 L0,473.113924 L0,38.8860759 L362.727273,0 L362.727273,40.6233618 L362.727273,494.564724 Z M296.4,236.170019 C296.4,230.661664 294.850868,226.007988 291.752557,222.219779 C288.654246,218.446139 284.892068,216.618326 280.465909,216.725361 L211.418182,218.395089 L211.418182,136.917782 C211.418182,131.694851 209.86905,127.38368 206.770739,123.995066 C203.672428,120.621021 199.910249,119.135714 195.484091,119.528173 L163.615909,122.353867 C159.189751,122.746327 155.427572,124.842093 152.329261,128.630302 C149.23095,132.403942 147.681818,136.789664 147.681818,141.798526 L147.681818,219.936377 L78.6340909,221.606105 C74.2079324,221.713139 70.4457541,223.4664 67.3474432,226.855014 C64.2491322,230.22906 62.7,234.272277 62.7,238.995713 L62.7,273.004287 C62.7,277.727723 64.2491322,281.77094 67.3474432,285.144986 C70.4457541,288.5336 74.2079324,290.286861 78.6340909,290.393895 L147.681818,292.063623 L147.681818,370.201474 C147.681818,375.210336 149.23095,379.596058 152.329261,383.369698 C155.427572,387.157907 159.189751,389.253673 163.615909,389.646133 L195.484091,392.471827 C199.910249,392.864286 203.672428,391.378979 206.770739,388.004934 C209.86905,384.61632 211.418182,380.305149 211.418182,375.082218 L211.418182,293.604911 L280.465909,295.274639 C284.892068,295.381674 288.654246,293.553861 291.752557,289.780221 C294.850868,285.992012 296.4,281.338336 296.4,275.829981 L296.4,236.170019 Z",
  ],
};
