import Loadable from "@loadable/component";

const PodcastSharingLinks = Loadable(() => import("./PodcastSharingLinks"));

function PodcastSharingLinksAsyncLoading(props) {
  return null;
}

const PodcastSharingLinksContainer = (props) => {
  return (
    <PodcastSharingLinks
      {...props}
      fallback={<PodcastSharingLinksAsyncLoading />}
    />
  );
};

export default PodcastSharingLinksContainer;
