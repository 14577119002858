import Loadable from "@loadable/component";

const EpisodeSharingLinks = Loadable(() => import("./EpisodeSharingLinks"));

function EpisodeSharingLinksAsyncLoading(props) {
  return null;
}

const EpisodeSharingLinksContainer = (props) => {
  return (
    <EpisodeSharingLinks
      {...props}
      fallback={<EpisodeSharingLinksAsyncLoading />}
    />
  );
};

export default EpisodeSharingLinksContainer;
